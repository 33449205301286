import { Functions, Storage } from "vuebase";
import { ImageData, ImageSize } from "coho-ui";

const storage = Storage.storage;
const functions = Functions.functions;

export default {
    uploadImage(
        file: File,
        path: string,
        updateProgress: (progress: number) => void,
        cachePolicy: Storage.CachePolicy = Storage.CachePolicy.Private,
        size?: ImageSize,
        quality?: number,
    ): Promise<ImageData | void> {
        if (!storage) {
            return Promise.reject("error");
        }

        const image: ImageData = {
            original: "",
        };

        return storage
            .upload(file, path, updateProgress, cachePolicy)
            .then((path) => {
                image.path = path;
                return storage?.getUrl(image.path);
            })
            .then((originalUrl) => {
                image.original = originalUrl;
                return this.thumbnail(image.path as string, size, quality);
            })
            .then((thumbnail) => {
                return storage?.getUrl(thumbnail);
            })
            .then((thumbnailUrl) => {
                image.thumbnail = thumbnailUrl;
                return image;
            });
    },
    async thumbnail(path: string, size?: ImageSize, quality?: number): Promise<string> {
        return functions
            ?.call("generateThumbnail", {
                imagePath: path,
                size: size,
                quality: quality,
            })
            .then((result: { data: string }) => {
                return result.data;
            });
    },
};
