
import { defineComponent, inject, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ImageData } from "coho-ui";
import { Database, Storage } from "vuebase";
import { CartItem, Product } from "../main";
import marked from "marked";
import server from "@/server";
import OrderController from "@/controllers/order-controller";

export default defineComponent({
    name: "Customize",
    setup() {
        const route = useRoute();
        const router = useRouter();
        const database = inject(Database.InjectionKey);
        let products = database?.collection<Product>("products").documents();
        const selectedProduct = ref<Database.Document<Product>>();
        const orderController = new OrderController();

        const print = ref<Partial<CartItem>>({ selections: {}, quantity: 1 });

        if (route.params.id) {
            selectedProduct.value = database
                ?.collection("products")
                .document(route.params.id as string) as Database.Document<Product>;
            print.value.productId = selectedProduct.value.id;
        }

        watch(
            print,
            () => {
                if (print.value?.productId != selectedProduct.value?.id) {
                    selectedProduct.value = database
                        ?.collection("products")
                        .document(print.value?.productId as string) as Database.Document<Product>;
                    print.value.selections = {};
                }

                if (print.value && selectedProduct.value?.data) {
                    print.value.price = orderController.calculateItemPrice(print.value);
                }
            },
            { deep: true, immediate: true },
        );

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "orders/" + new OrderController().order?.id ?? "unknown",
                progressCallback,
                Storage.CachePolicy.Day,
                { width: 1800, height: 1800 },
                100,
            );
        }

        function validate(print: Partial<CartItem>): boolean {
            return print.image == undefined;
        }

        function addToCart(): void {
            orderController.addItem(print.value as CartItem);
            router.push("/cart");
        }

        return { products: products, selectedProduct, print, uploadImage, validate, addToCart, marked };
    },
});
