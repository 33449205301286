<template>
    <c-panel>
        <c-grid>
            <c-grid-item class="width-60-all width-100-sm">
                <c-image-picker
                    v-model="print.image"
                    :upload="uploadImage"
                    :aspect-ratio="print.size.width / print.size.height"
                    v-if="print.size"
                />
            </c-grid-item>
            <c-grid-item class="width-40-all width-100-sm">
                <c-grid class="widths-100-all">
                    <c-grid-item>
                        <c-select
                            label="Product"
                            :options="products.filter((p) => p.data?.category == 'Custom Print')"
                            :display="(product) => product?.data?.name"
                            :map="(product) => product.id"
                            v-model="print.productId"
                        >
                        </c-select>
                    </c-grid-item>
                    <c-grid-item>
                        <c-print-size-picker v-model="print.size" />
                    </c-grid-item>
                    <template v-if="selectedProduct?.data">
                        <c-grid-item v-for="selection in selectedProduct.data.selections" :key="selection.name">
                            <c-select
                                :label="selection.name"
                                :options="selection.options"
                                :display="(option) => option?.name"
                                :showInfo="(option) => option?.description"
                                :map="(option) => option?.name"
                                v-if="print.selections"
                                v-model="print.selections[selection.name]"
                            >
                                <template #info="slotProps">
                                    <p v-html="marked(slotProps.option.description)"></p>
                                    <c-image
                                        class="mt-2"
                                        v-if="slotProps.option.images[0]"
                                        @click.stop
                                        :zoom="true"
                                        :image="slotProps.option.images[0]"
                                    />
                                </template>
                            </c-select>
                        </c-grid-item>
                    </template>
                    <c-grid-item class="width-grow-all">
                        <c-numberbox label="Quantity" v-model="print.quantity" />
                    </c-grid-item>
                    <c-grid-item class="width-shrink-all">
                        <h1 class="mt-4 mb-0" style="font-size: 1.7rem" v-if="print.price">${{ print.price }}</h1>
                    </c-grid-item>

                    <c-grid-item class="flex f-justify-end width-100-all">
                        <c-button @click="addToCart()" :disabled="validate(print)">Add to Cart</c-button>
                    </c-grid-item>
                </c-grid>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { defineComponent, inject, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ImageData } from "coho-ui";
import { Database, Storage } from "vuebase";
import { CartItem, Product } from "../main";
import marked from "marked";
import server from "@/server";
import OrderController from "@/controllers/order-controller";

export default defineComponent({
    name: "Customize",
    setup() {
        const route = useRoute();
        const router = useRouter();
        const database = inject(Database.InjectionKey);
        let products = database?.collection<Product>("products").documents();
        const selectedProduct = ref<Database.Document<Product>>();
        const orderController = new OrderController();

        const print = ref<Partial<CartItem>>({ selections: {}, quantity: 1 });

        if (route.params.id) {
            selectedProduct.value = database
                ?.collection("products")
                .document(route.params.id as string) as Database.Document<Product>;
            print.value.productId = selectedProduct.value.id;
        }

        watch(
            print,
            () => {
                if (print.value?.productId != selectedProduct.value?.id) {
                    selectedProduct.value = database
                        ?.collection("products")
                        .document(print.value?.productId as string) as Database.Document<Product>;
                    print.value.selections = {};
                }

                if (print.value && selectedProduct.value?.data) {
                    print.value.price = orderController.calculateItemPrice(print.value);
                }
            },
            { deep: true, immediate: true },
        );

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "orders/" + new OrderController().order?.id ?? "unknown",
                progressCallback,
                Storage.CachePolicy.Day,
                { width: 1800, height: 1800 },
                100,
            );
        }

        function validate(print: Partial<CartItem>): boolean {
            return print.image == undefined;
        }

        function addToCart(): void {
            orderController.addItem(print.value as CartItem);
            router.push("/cart");
        }

        return { products: products, selectedProduct, print, uploadImage, validate, addToCart, marked };
    },
});
</script>
